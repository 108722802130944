<script setup lang="ts">
import { MediaImageFragment } from '~/api/graphql/generated'

const props = defineProps<{
  title: string
  subTitle?: string
  image?: {
    small?: MediaImageFragment
    large?: MediaImageFragment
  }
  parentName?: string
}>()

const parentName = props.parentName || getCurrentInstance()?.parent?.type.__name || ''
</script>

<template>
  <Section large wide :class="['section-special', { 'has-image': !!image }]" :parent-name="parentName">
    <div class="container">
      <div class="header grid">
        <div v-animate="{ animation: 'vertical-reveal' }" class="background">
          <Picture
            v-if="image"
            v-animate="{ animation: 'vertical-reveal' }"
            class="image"
            type="hero"
            :small="image.small"
            :large="image.large"
          />
        </div>
        <SectionTitle class="text tl-6 ds-4" :title="title" />
      </div>
      <div class="content grid">
        <div class="t-10 t-o-1">
          <div
            v-if="subTitle"
            v-animate="{ animation: 'vertical-reveal' }"
            class="sub-title text-medium"
            v-html="subTitle"
          />
          <slot />
        </div>
      </div>
    </div>
  </Section>
</template>
<style scoped lang="scss">
.section {
  @include responsive(--overlap, 0px, 60px, 160px);
  &.has-image {
    @include fluid(--min-height, 320px, 620px);
    .text {
      --c-title: var(--c-white);
    }
  }
}

.header {
  position: relative;
  min-height: var(--min-height);
}

.text {
  position: relative;
  :deep(.title) {
    hyphens: none;
    overflow-wrap: initial;
  }
}

.background {
  position: absolute;
  width: 100vw;
  height: 100%;

  --ml: calc(50% - min(50vw, calc(var(--layout-max-width) / 2)));
  margin-left: var(--ml);
  max-width: calc(100% + (-1 * var(--ml)));

  background: var(--c-background-medium);
}

.image {
  display: flex;

  :deep(img) {
    @include fill;
  }
  &:before {
    content: '';
    @include fill;
    background: linear-gradient(104.04deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
  }
}

.text {
  @include fluid(margin-top, 36px, 80px);
  padding-bottom: var(--overlap);
  z-index: 1;
}
.content {
  position: relative;
  @include fluid(padding-top, 36px, 56px);

  margin-top: calc(-1 * var(--overlap));

  background: var(--c-white);
  z-index: 1;
}

.sub-title {
  margin-bottom: 48px;
}
</style>
